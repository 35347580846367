var PopOverForm = (function ($) {

    var
        mailVars = {
            contact: {
                form: $('#form_contact .form_fields'),
                name: $('#name_contact'),
                email: $('#email_contact'),
                more: $('#more_contact'),
                submit: $('#submit_contact'),
                honey: $('#honey_contact'),
                info: $('#info_contact')
            }
        };

    var init = function () {

        $("body").on('click', '[data-form]', function (event) {
            event.preventDefault();

            var
                formWrapper = $('.form_wrapper'),
                thisForm = $(this).attr('data-form');

            openMail(thisForm, formWrapper);
        });

        $("body").on('click', '.fermer_form', function (event) {
            event.preventDefault();
            closeMail();
        });

        //Fermer menu ouvert si click à l'exterieur
        $(document).mouseup(function (e) {
            var formContainer = $(".form");

            // If the target of the click isn't the formContainer
            if (($('body').attr('data-mail-opened') === "true") && (!formContainer.is(e.target) && formContainer.has(e.target).length === 0)) {
                closeMail();
            }
        });

        $('[data-action="send_mail"]').on('click', function (e) {
            e.preventDefault();

            var
                that = $(this),
                thisForm = that.closest('.form'),
                datas = thisForm.serialize();

            manageMail("contact", $('.form_wrapper'), datas);

            return false;
        });
    };

    function manageMail(thisForm, formWrapper, datas) {

        //var url = "php/mails/mail_" + thisForm + ".php";
        var url = window.location.protocol + "//" + window.location.host + "/templates/mytmpl/php/mails/mail_" + thisForm + ".php";

        mailVars[thisForm].form.on('input', mailVars[thisForm].form.name + ',' + mailVars[thisForm].form.email + ',' + mailVars[thisForm].form.more, function () {
            mailVars[thisForm].info.html('').slideUp();
        });


        if (validate(thisForm)) {
            console.log(mailVars[thisForm].form.serialize());
            console.log(url);
            $.ajax({
                type: "POST",
                url: url,
                data: datas,
                dataType: "json"
            }).done(function (data) {
                console.log("data");
                console.log(data);
                if (data.success) {
                    mailVars[thisForm].name.val('');
                    mailVars[thisForm].email.val('');
                    mailVars[thisForm].more.val('');
                    mailVars[thisForm].honey.val('');
                    mailVars[thisForm].info.html('Message envoyé !').css('color', 'green').slideDown();
                    setTimeout(function () {
                        closeMail(formWrapper);
                    }, 2000);
                } else {
                    mailVars[thisForm].info.html('Impossible d\'envoyer le mail, désolé !').css('color', 'red').slideDown();
                }
            });
        }
    }

    function openMail(thisForm, formWrapper) {

        console.log(thisForm);
        new TimelineMax({

            })
            .to('.form_wrapper', 0.3, {
                autoAlpha: 1
            })
            .to('.form_bg', 0.3, {
                xPercent: -100
            }, 0)
            .to('#form_' + thisForm, 0.3, {
                autoAlpha: 1,
                rotateY: 0
            }, 0)
            ;

        //$('#form_' + thisForm).show();
        $('body').attr('data-mail-opened', 'true');
    }

    function closeMail() {

        new TimelineMax()
            .to('.form_wrapper', 0.15, {
                autoAlpha: 0
            })

            .set('.form', {
                autoAlpha: 0,
                scale: 1,
                rotateY: -90
            })
            .set('.form_bg', {
                xPercent: 0
            });

        //$('.form').hide();
        $('body').attr('data-mail-opened', 'false');
    }

    function validate(thisForm) {
        var valid = true;
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (!regex.test(mailVars[thisForm].email.val())) {
            mailVars[thisForm].email.css({
                'border-bottom-color': 'red',
                'border-bottom-width': '5px'
            });
            valid = false;
        }

        if ($.trim(mailVars[thisForm].name.val()) === "") {
            mailVars[thisForm].name.css({
                'border-bottom-color': 'red',
                'border-bottom-width': '5px'
            });
            valid = false;
        }
        if ($.trim(mailVars[thisForm].more.val()) === "") {
            mailVars[thisForm].more.css({
                'border-bottom-color': 'red',
                'border-bottom-width': '5px'
            });
            valid = false;
        }

        if ($.trim(mailVars[thisForm].honey.val()) !== "") {
            valid = false;
        }

        return valid;
    }

    return {
        init: init
    };
})(jQuery);