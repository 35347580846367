var MainMenu = (function ($) {


    var tlNavTransition = new TimelineMax({id: "tlNavTransition",paused:true, yoyo: true}),
        $menuIcon = $('#menu_icon, .menu_icon'),
        $menuIconBar1 = $menuIcon.children('.bar').eq(0),
        $menuIconBar2 = $menuIcon.children('.bar').eq(1),
        $menuIconBar3 = $menuIcon.children('.bar').eq(2),
        $menu = $('#main_menu_container');
        
    tlNavTransition
        .to($menuIconBar1, 0.5, {css: {transform: "translateY(0.55rem) rotate(45deg)"}, transformOrigin:"left top",ease: "expo.out"},0)
        .to($menuIconBar2, 0.5, {css: {transform: "translateX(-0.25rem) rotate(45deg)"}, transformOrigin:"left center",ease: "expo.out"},0)
        .to($menuIconBar3, 0.5, {css: {transform: "translateY(-0.55rem) rotate(-45deg)"}, transformOrigin:"left bottom",ease: "expo.out"},0)
        .to($menu, 0.5, {autoAlpha: 1,ease: Power3.easeOut},0.25);


    var init = function () {

        $menuIcon.on('click', function (e) {
            e.preventDefault();

            var $thisMenuIcon = $(this);

            MainMenu.menuToggle($thisMenuIcon);
        });

        //Fermer menu ouvert si click à l'exterieur
        $(document).mouseup(function (e) {
            var container = $("#main_menu_wrapper");

            // If the target of the click isn't the container
            if (($menuIcon.hasClass('opened')) && (!container.is(e.target) && container.has(e.target).length === 0) && (!gsap.isTweening(["#menu", $menuIconBar1]))) {
                 MainMenu.closeMenu($menuIcon);
            }
        });
    };

    function menuToggle($thisMenuIcon){

        if($thisMenuIcon == void 0){
            $thisMenuIcon = $menuIcon;
        }

        if ($thisMenuIcon.hasClass('closed')) {
            if (!gsap.isTweening(["#menu", $menuIconBar1])) {
                MainMenu.openMenu($thisMenuIcon);
            }
        } else {
            if (!gsap.isTweening(["#menu", $menuIconBar1])) {
                MainMenu.closeMenu($thisMenuIcon);
            }
        }
    }

    function openMenu($thisMenuIcon) {
        
        $thisMenuIcon.removeClass('closed').addClass('opened');

        $('body').css('overflow-y', 'hidden');
        
        tlNavTransition.play();
        
        
    }
    
    function closeMenu($thisMenuIcon) {

        $thisMenuIcon.removeClass('opened').addClass('closed');

        $('body').css('overflow-y', 'initial');

        tlNavTransition.reverse();
    }

    function toggleClassMenu(thisLinkDatas) {

        $('[data-action="goToLink"]').removeClass('active').parent('li').removeClass('active').parent('ul').parent('li').removeClass('active');

        $('[data-action="goToLink"][data-categoryID="' + thisLinkDatas.categoryID + '"][data-articleID="' + thisLinkDatas.articleID + '"]').addClass('active').parent('li').addClass('active').parent('ul').parent('li').addClass('active');

    }

    return {
        init: init,
        menuToggle: menuToggle,
        openMenu: openMenu,
        closeMenu: closeMenu,
        toggleClassMenu: toggleClassMenu
    };



})(jQuery);