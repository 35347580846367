var Carousel = (function ($) {

    // CONTROLS
    var carouselPrev = $('.carousel_nav#prev'),
        carouselNext = $('.carousel_nav#next');

    // DATAS
    /* var carouselDatas = {
        0: {
            picture: "0.png",
            color: 'rose1',
            title: 'Natation artistique',
            description: 'AQUA & SYNCHRO 66 est un club de natation qui évolue sur la ville de Perpignan et celle de Rivesaltes et qui propose aux enfants et aux adultes une école de natation qui oriente sur la pratique de la natation en compétition dans deux disciplines.',
            mybutton: {
                text: 'en savoir plus',
                link: 'articles?sujet=nat_artistique',
                target: '_self'
            }
        },
        1: {
            picture: "1.png",
            color: 'vert1',
            title: 'Natation sportive',
            description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy euismod tincidunt ut, Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy euismod tincidunt ut.',
            mybutton: {
                text: 'en savoir plus',
                link: 'articles?sujet=nat_sportive',
                target: '_self'
            }
        }
    }; */

    // DOM
    var carousel = $('#carousel'),
        carouselPicture = $('#carouselPicture'),
        carouselTitle = $('#carouselTitle'),
        carouselDescription = $('#carouselDescription'),
        carouselLink = $('#carouselLink'),
        imgSrc = 'images/carousel/';


    var dataBcolor = $('[data-bcolor]'),
        dataColor = $('[data-color]');

    var carouselDatasLength = Object.keys(activities).length;
    //var carouselDatasLength = 2;

    var carouselDatasIndex = 0;

    var carouselInit = function () {
        
        carouselPrev.on('click', function () {
            carouselControl.prev();
        });

        carouselNext.on('click', function () {
            carouselControl.next();
        });
    };

    var  carouselControl = {

        'next' : function(){
            carouselDatasIndex = carouselDatasIndex + 1;
            if (carouselDatasIndex >= carouselDatasLength){
                carouselDatasIndex = 0;
            }
            this.carouselPlay();
        },
        
        'prev' : function(){
            carouselDatasIndex = carouselDatasIndex - 1;
            if (carouselDatasIndex < 0){
                carouselDatasIndex = carouselDatasLength - 1;
            }
            this.carouselPlay();
        },

        'carouselPlay' : function(){            
            var carouselData = activities[carouselDatasIndex];
            console.log('carouselData');
            console.log(carouselData);
            carouselTransition.title();
            carouselTransition.picture();
            carouselTransition.description();
            carouselTransition.link();
            carouselTransition.color();
        }

    };

    var carouselTransition = {

        title: function(){
            carouselTitle.html(activities[carouselDatasIndex].titre);
        },
        description: function(){
            carouselDescription.html(activities[carouselDatasIndex].description2);
        },
        picture: function(){
            carouselPicture.attr('src', imgSrc + activities[carouselDatasIndex].img);
        },
        color: function(){
            dataBcolor.attr('data-bcolor', activities[carouselDatasIndex].color);
            dataColor.attr('data-color', activities[carouselDatasIndex].color);
        },
        link: function(){
            carouselLink.attr('href', activities[carouselDatasIndex].mybutton_link);
        }

    };

    return {
        carouselInit : carouselInit
    };


})(jQuery);