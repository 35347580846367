var LogoAnim = (function ($) {


    var 
        logo_svg = $('#logo_svg'),
        logo_g = $('#logo_svg #logo_g'),
        logo_circle = $('#logo_svg #logo_g #cercle'),
        logo_texte = $('#logo_svg #logo_g #texte'),
        logo_nageur = $('#logo_svg #logo_g #nageur'),
        logo_nageuse = $('#logo_svg #logo_g #nageuse'),

        logo_anim = new TimelineMax()
    ;

    var init = function (){

        logo_anim   
            .to(logo_circle,  {duration: 1, autoAlpha: 1, scale: 1})
            .to(logo_nageuse, {duration: 1, autoAlpha: 1, translateY: 0}, '-=70%')
            .to(logo_nageur,  {duration: 1, autoAlpha: 1, translateY: 0}, '-=50%')
            .to(logo_texte,   {duration: 1, autoAlpha: 1, translateY: 0}, '-=70%')
        ;
    };

    return {
        init: init
    };

})(jQuery);